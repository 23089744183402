const firebaseConfig = {
  apiKey: "AIzaSyBp1irkX6Zd28NmCyzcx0QgplUpiPdwW9w",
  authDomain: "trashpedia-5176c.firebaseapp.com",
  projectId: "trashpedia-5176c",
  storageBucket: "trashpedia-5176c.appspot.com",
  messagingSenderId: "667068538699",
  appId: "1:667068538699:web:d0e3c5ad5a6273395a92ce",
  measurementId: "G-6K9PKLXTZM",
}

const collectionName = "ZeroWasteMalaysiaQuizCollection"

export { firebaseConfig, collectionName }
