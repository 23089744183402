import * as React from "react"
import { useState, useEffect } from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { Container } from "react-bootstrap"
import useSceenSize from "../hooks/useScreenSize"

import Header2 from "../components/header2"
import Footer from "../components/footer"

import quizData from "../data/quiz.json"
import selection from "../data/selection.json"

import img1 from "../images/quiz_image1.jpg"
import img2 from "../images/quiz_image2.jpeg"
import img3 from "../images/quiz_image3.jpeg"

import home from "../images/quiz_img/home-svgrepo-com.svg"
import recycle from "../images/quiz_img/recycle-svgrepo-com.svg"
import bottle from "../images/quiz_img/bottle-earth-eco-svgrepo-com.svg"
import finish from "../images/quiz_img/finish-line-flag-svgrepo-com.svg"
import wasteQuizLogo from "../images/quiz_img/Waste_Quiz_logo_SVG.svg"
import info from "../images/quiz_img/information-circle-svgrepo-com.svg"

import { storeData } from "../utils/firebaseStore"

import { useIntl } from "gatsby-plugin-intl"
import {
  FacebookShareButton,
  FacebookIcon as FacebookShareIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"

import { RiArrowGoForwardFill } from "react-icons/ri"

const QuizContainer = styled.div`
  background-color: white;
  width: 100%;
  text-align: center;
  height: ${props => (props.section === 3 ? "" : "90vh")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5vh;
  font-weight: bold;
  padding-bottom: 50px;
  @media (max-width: 375px) {
    @media (max-height: 667px) {
      height: unset;
    }
  }
  @media (max-width: 992px) {
    padding-bottom: 0px;
  }
`
const MainContainer = styled(Container)`
  height: 100vh;
  padding: 0 !important;
  font-family: "Poppins", sans-serif;
`
const StarterContainer = styled.div`
  background-color: #84b250;
  border-radius: 25px;
  height: 40vh;
  width: min(100vh, 95vw);
  display: flex;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  @media (max-width: 1920px) {
    width: 1100px;
    height: 60vh;
  }
  @media (max-width: 992px) {
    height: 40vh;
    width: min(100vh, 95vw);
  }
`
const StarterContainerComponent1 = styled.div`
  height: 100%;
  width: 50%;
  background-image: url(${img1});
  background-size: cover;
  background-position: center;
`
const StarterContainerComponent2 = styled.div`
  background-color: #84b250;
  height: 100%;
  width: 50%;
  padding: 3vh;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const StarterContainerFontType1 = styled.p`
  font-size: min(3vh, 5vw);
  color: white;
  font-weight: bold;
  line-height: min(3vh, 5vw);
  @media (max-width: 1920px) {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
  }
`
const StarterContainerFontType2 = styled.p`
  font-size: min(1.5vh, 2.5vw);
  color: white;
  line-height: min(1.8vh, 2.7vw);
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  @media (max-width: 1920px) {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 30px;
  }
`
const StarterContainerFontType3 = styled.p`
  font-size: min(1.2vh, 2vw);
  color: black;
  line-height: min(1.5vh, 2.2vw);
  @media (max-width: 1920px) {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
`
const StarterContainerButton = styled.button`
  background-color: #84b250;
  border: ${props =>
    props.state === "start" ? "3px solid white" : "3px solid #F8F8F8"};
  font-size: min(2vh, 3.5vw);
  color: white;
  border-radius: 25px;
  width: ${props => (props.state === "start" ? "fit-content" : "100%")};
  padding: 8px 45px;
  font-weight: ${props => (props.state === "start" ? "300" : "bold")};
  margin-top: 2vh;
  @media (max-width: 1920px) {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
  }
  @media (max-width: 992px) {
    font-size: 5vw;
  }
`
const StarterContainerButton2 = styled.button`
  background-color: #4e4e4e;
  border: 3px solid #f8f8f8;
  font-size: min(2vh, 3.5vw);
  color: white;
  border-radius: 25px;
  width: 100%;
  padding: 8px 45px;
  margin-top: 0.5vh;
  font-weight: bold;
  @media (max-width: 1920px) {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
  }
  @media (max-width: 992px) {
    font-size: 5vw;
  }
`
const QuizSection = styled.div`
  background-color: #f8f8f8;
  border-radius: 25px;
  display: flex;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  height: 45vh;
  width: min(100vh, 95vw);
  @media (max-width: 1920px) {
    width: 1100px;
    height: 60vh;
  }
  @media (max-width: 992px) {
    height: 70vh;
    width: min(100vh, 95vw);
  }
  @media (max-width: 375px) {
    @media (max-height: 667px) {
      height: 75vh;
      margin-bottom: 15px;
    }
  }
`
const QuestionContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  padding: 0px 50px;
`
const QuestionContainerFontType1 = styled.p`
  font-size: min(2.5vh, 5vw);
  @media (max-width: 1920px) {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
  }
  @media (max-width: 992px) {
    font-size: 6vw;
  }
`
const ThankYouFont = styled.p`
  font-size: min(3.5vh, 5vw);
  @media (max-width: 1920px) {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
  }
  @media (max-width: 992px) {
    font-size: 6vw;
  }
`
const Selector = styled.select`
  font-size: 2vh;
  text-align: center;
  background-color: white;
  border-radius: 5px;
  border: 1px #496973 solid;
  padding: 5px 15px;
`
const ButtonGroupButton = styled.button`
  font-size: min(2vh, 3.5vw);
  color: ${props => (props.buttonColor ? "white" : "black")};
  background-color: ${props => (props.buttonColor ? "#BABABA" : "#FFFAFA")};
  border-color: ${props => (props.buttonColor ? "#BABABA" : "black")};
  border-style: ${props => (props.buttonColor ? "solid" : "")};
  border-width: 1px;
  border-radius: 5px;
  width: fit-content;
  padding: 5px 30px;
  margin-top: min(0.5vh, 0.85vw);
  margin-left: min(1vh, 1.5vw);
  margin-right: min(1vh, 1.5vw);
  @media (max-width: 992px) {
    width: 55vw;
    margin-bottom: min(1vh, 1.5vw);
  }
`
const NumButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const NumButton = styled.button`
  padding: 0;
  border: none;
  background: none;
`
const NumButtonValueDisplayField = styled.div`
  background-color: #f4f4f4;
  width: min(10vh, 15vw);
  border: 1px #496973 solid;
  font-size: min(3vh, 5vw);
  margin: auto min(3vh, 5vw) auto min(3vh, 5vw);
`
const ResultContainer = styled.div`
  width: min(150vh, 95vw);
  padding: 50px 0px;
`
const ResultTitle = styled.div`
  font-size: min(3vh, 5vw);
  text-align: left;
  color: #5a5a5a;
  @media (max-width: 768px) {
    text-align: center;
  }
`
const ResultTitle2 = styled.div`
  font-size: min(1.5vh, 3vw);
  text-align: right;
  color: black;
  margin-top: auto;
  text-decoration: underline;
  @media (max-width: 768px) {
    text-align: center;
  }

  a {
    color: black;
  }
`
const ResultTitleLine = styled.div`
  width: 100%;
  background-color: #7ba64b;
  height: 3px;
  margin-top: 5px;
  margin-bottom: 25px;
  @media (max-width: 992px) {
    display: none;
  }
`
const ResultSection1 = styled.div`
  background: linear-gradient(180deg, #4e4e4e 0%, rgba(78, 78, 78, 0.7) 100%),
    url(${img2});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: min(35vh, 90vw);
  border-radius: 10px;
  margin-bottom: min(3vh, 5vw);
  font-size: min(2vh, 5.5vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  @media (max-width: 1920px) {
    font-style: normal;
    font-size: 25px;
  }
  @media (max-width: 375px) {
    @media (max-height: 667px) {
      font-size: 17px;
    }
  }
  @media (max-width: 992px) {
    height: min(40vh, 90vw);
    margin-top: 20px;
  }
`
const ResultSection2 = styled.div`
  background-color: #84b250;
  border-radius: 10px;
  width: 100%;
  height: min(12vh, 35vw);
  color: white;
  font-size: min(3vh, 6vw);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: min(3vh, 5vw);
  padding-top: min(1.5vh, 3vw);
  padding-bottom: min(1.5vh, 3vw);
  @media (max-width: 1920px) {
    font-style: normal;
    font-size: 30px;
  }
  @media (max-width: 375px) {
    @media (max-height: 667px) {
      font-size: 17px;
    }
  }
  @media (max-width: 992px) {
    font-size: 6vw;
  }
`
const ResultSection3 = styled.div`
  background-color: #f3f3f3;
  width: 100%;
  height: 30vh;
  margin-bottom: min(3vh, 6vw);
  font-size: min(3vh, 6vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #4e4e4e;
  border-radius: 12px;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 1920px) {
    font-style: normal;
    font-size: 25px;
  }
  @media (max-width: 375px) {
    @media (max-height: 667px) {
      font-size: 17px;
    }
  }
  @media (max-width: 992px) {
    font-size: 5vw;
  }
`
const ResultSectionText1 = styled.div`
  font-size: min(5vh, 12vw);
  color: #84b250;
  font-weight: bold;
  @media (max-width: 1920px) {
    font-style: normal;
    font-size: 50px;
  }
  @media (max-width: 992px) {
    font-size: 48px;
  }
`
const ResultSectionText2 = styled.div`
  font-size: min(9vh, 12vw)
  color: white;
  font-weight: bold;
  @media (max-width: 1920px) {
    font-style: normal;
    font-size: 70px;
  }
  @media (max-width: 992px){
    font-size: 48px;
  }
`
const ResultSectionText3 = styled.div`
  font-size: min(3vh, 6vw);
  color: #84b450;
  font-weight: bold;
  margin-bottom: min(1vh, 1.5vw);
  line-height: 35px;
  @media (max-width: 1920px) {
    font-style: normal;
    font-size: 30px;
  }
  @media (max-width: 992px) {
    font-size: 6vw;
  }
`
const ResultSectionText4 = styled.div`
  font-size: min(2vh, 4vw);
  color: black;
  font-weight: bold;
  margin-bottom: min(1vh, 1.5vw);
  @media (max-width: 1920px) {
    font-style: normal;
    font-size: 24px;
  }
  @media (max-width: 992px) {
    font-size: 5vw;
  }
`
const ResultSectionText5 = styled.div`
  font-size: min(2vh, 4vw);
  color: black;
  font-weight: normal;
  margin-bottom: min(2vh, 3.5vw);
  @media (max-width: 1920px) {
    font-size: 18px;
  }
  @media (max-width: 992px) {
    font-size: 4vw;
  }
`
const ResultSection4 = styled.div`
  background: linear-gradient(
      0deg,
      rgba(243, 243, 243, 0.85),
      rgba(243, 243, 243, 0.85)
    ),
    url(${img3});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: min(45vh, 100vw);
  border-radius: 10px;
  margin-bottom: min(3vh, 5vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
`
const ResultButton = styled.button`
  background-color: #7ba64b;
  border-style: none;
  font-size: min(2vh, 5vw);
  margin-left: auto;
  margin-right: auto;
  border-radius: 60px;
  color: white;
  font-weight: bold;
  padding: 5px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  @media (max-width: 768px) {
    width: 80%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`
const AgainText = styled.div`
  margin-left: 1vh;
`
const AgainIcon = styled(RiArrowGoForwardFill)`
  height: 100%;
`
const IconGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
`
const IconDiv = styled.div`
  margin-left: 0.2vh;
  margin-right: 0.2vh;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2vh;
`
const ProgressDiv = styled.div`
  position: absolute;
  top: 2.5vh;
  height: 5vh;
  width: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const ProgressDiv2 = styled.div`
  position: absolute;
  top: 2.5vh;
  height: 5vh;
  width: min(100vh, 95vw);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const ProgressStatusLogo = styled.img`
  background-color: ${props => (props.state ? "#84B250" : "#5A5A5A")};
  height: 7vh;
  width: 7vh;
  padding: 1vh;
  border-radius: 60px;
  @media (min-width: 480px) {
    height: 6vh;
    width: 6vh;
  }
`
const MainDiv = styled.div`
  position: relative;
  height: 40vh;
  width: min(100vh, 95vw);
  @media (max-width: 1920px) {
    width: 1100px;
    height: 60vh;
  }
  @media (max-width: 992px) {
    height: 75vh;
    width: min(100vh, 95vw);
  }
  @media (max-width: 375px) {
    @media (max-height: 667px) {
      height: unset;
    }
  }
`
const SubDiv = styled.div`
  height: 5vh;
`
const CompleteLine = styled.div`
  background-color: #7ba64b;
  height: 0.5vh;
  width: ${props => props.progress}%;
`
const StarterContainerWidth = styled.div`
  background-color: #84b250;
  height: 65vh;
  width: 95vw;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 0;
  @media (max-width: 992px) {
    height: 80vh;
    margin-bottom: 15px;
  }
`
const StarterContainerComponent1Width = styled.div`
  height: 40%;
  width: 100%;
  background-image: url(${img1});
  background-size: cover;
`
const StarterContainerComponent2Width = styled.div`
  height: 60%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StarterContainerWidthFont1 = styled.div`
  font-size: 6vw;
  color: white;
  font-weight: bold;
  line-height: 7vw;
  margin-bottom: 4vw;
`
const StarterContainerWidthFont2 = styled.div`
  font-size: 4vw;
  color: white;
  line-height: 6vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  font-weight: normal;
`
const StarterContainerWidthLine = styled.div`
  background-color: white;
  width: 50%;
  height: 0.5vw;
  margin-bottom: 6vw;
  margin-top: 3vw;
`
const StarterContainerWidthFont3 = styled.div`
  font-size: 3.5vw;
  color: black;
  line-height: 4vw;
  font-weight: normal;
  margin-bottom: 3vw;
`
const StarterContainerWidthBottom = styled.button`
  background-color: #84b250;
  border: 3px solid white;
  font-size: 5vw;
  color: white;
  border-radius: 25px;
  width: fit-content;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 0.5vh;
`
const QuizLogo = styled.img`
  height: min(8vh, 15vw);
  @media (max-width: 992) {
    height: min(5vh, 15vw);
  }
`
const QuizLogoContainer = styled.div`
  width: min(100vh, 95vw);
  display: flex;
  margin-bottom: 20px;
  @media (max-width: 1920px) {
    width: 1100px;
  }
  @media (max-width: 992px) {
    width: min(100vh, 95vw);
  }
  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 5vw;
  }
`
const ProgressLine = styled.div`
  background-color: #d9d9d9;
  width: 60vh;
  height: 1.5vh;
  display: flex;
  align-items: center;
`
const MainDiv2 = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`
const ProgressIcon = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vh;
  width: 20vh;
  left: ${props => props.state}%;
`
const ProgressFontType1 = styled.div`
  font-size: 2vh;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
`
const ResultHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const InfoLogo = styled.img`
  height: min(2.5vh, 7vw);
  margin-right: 5px;
`
const LogoDiv = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  @media (max-width: 768px) {
    justify-content: center;
  }

  @media (max-width: 992px) {
    margin-top: 10px;
  }
`
const MobilePaddingDiv = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`

const SelectionContainer = styled.div`
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const StarterContainerSection = props => {
  const intl = useIntl()
  const { width } = useSceenSize()
  return width >= 992 ? (
    <StarterContainer>
      <StarterContainerComponent1 />
      <StarterContainerComponent2>
        <StarterContainerFontType1>
          {intl.formatMessage({ id: "quiz.starter.p1" })}
        </StarterContainerFontType1>
        <StarterContainerFontType2>
          {intl.formatMessage({ id: "quiz.starter.p2" })}
        </StarterContainerFontType2>
        <StarterContainerFontType3>
          {intl.formatMessage({ id: "quiz.starter.p3" })}
        </StarterContainerFontType3>
        <StarterContainerButton state={"start"} onClick={props.nextSection}>
          {intl.formatMessage({ id: "quiz.starter.p4" })}
        </StarterContainerButton>
      </StarterContainerComponent2>
    </StarterContainer>
  ) : (
    <StarterContainerWidth>
      <StarterContainerComponent2Width>
        <StarterContainerWidthFont1>
          {intl.formatMessage({ id: "quiz.starter.p1" })}
        </StarterContainerWidthFont1>
        <StarterContainerWidthFont2>
          {intl.formatMessage({ id: "quiz.starter.p2" })}
        </StarterContainerWidthFont2>
        <StarterContainerWidthLine />
        <StarterContainerWidthFont3>
          {intl.formatMessage({ id: "quiz.starter.p3" })}
        </StarterContainerWidthFont3>
        <StarterContainerWidthBottom onClick={props.nextSection}>
          {intl.formatMessage({ id: "quiz.starter.p4" })}
        </StarterContainerWidthBottom>
      </StarterContainerComponent2Width>
      <StarterContainerComponent1Width />
    </StarterContainerWidth>
  )
}

//sectionArrCount quizArrCount quizData
const ProgressBar = props => {
  const intl = useIntl()
  const { width } = useSceenSize()
  let progress1 = 0
  let progress2 = 0
  let progress3 = 0
  let progressPercentage = 0
  if (props.cond === false) {
    progress1 =
      props.sectionArrCount === 0
        ? ((props.quizArrCount + 1) / props.quizData[0].length) * 100
        : props.sectionArrCount > 0 && 100
    progress2 =
      props.sectionArrCount === 1
        ? ((props.quizArrCount + 1) / props.quizData[1].length) * 100
        : props.sectionArrCount > 1 && 100
    progress3 =
      props.sectionArrCount === 2
        ? ((props.quizArrCount + 1) / props.quizData[2].length) * 100
        : props.sectionArrCount > 2 && 100
  }
  progressPercentage = (progress1 + progress2 + progress3) / 3

  return width > 992 ? (
    props.cond === false ? (
      <MainDiv2>
        <ProgressDiv>
          <ProgressLine>
            <CompleteLine progress={progressPercentage} />
          </ProgressLine>
          <ProgressIcon state={10}>
            <ProgressStatusLogo src={home} state={props.sectionArrCount >= 0} />
            <ProgressFontType1>
              {intl.formatMessage({ id: "quiz.progressbar.basic" })}
            </ProgressFontType1>
          </ProgressIcon>
          <ProgressIcon state={30}>
            <ProgressStatusLogo
              src={recycle}
              state={props.sectionArrCount >= 1}
            />
            <ProgressFontType1>
              {intl.formatMessage({ id: "quiz.progressbar.recycling" })}
            </ProgressFontType1>
          </ProgressIcon>
          <ProgressIcon state={50}>
            <ProgressStatusLogo
              src={bottle}
              state={props.sectionArrCount >= 2}
            />
            <ProgressFontType1>
              {intl.formatMessage({ id: "quiz.progressbar.bring" })}
            </ProgressFontType1>
          </ProgressIcon>
          <ProgressIcon state={70}>
            <ProgressStatusLogo
              src={finish}
              state={props.sectionArrCount >= 3}
            />
            <ProgressFontType1>
              {intl.formatMessage({ id: "quiz.progressbar.finish" })}
            </ProgressFontType1>
          </ProgressIcon>
        </ProgressDiv>
        <SubDiv></SubDiv>
      </MainDiv2>
    ) : (
      <MainDiv2>
        <ProgressDiv>
          <ProgressLine>
            <CompleteLine progress={100} />
          </ProgressLine>
          <ProgressIcon state={10}>
            <ProgressStatusLogo src={home} state={true} />
            <ProgressFontType1>
              {intl.formatMessage({ id: "quiz.progressbar.basic" })}
            </ProgressFontType1>
          </ProgressIcon>
          <ProgressIcon state={30}>
            <ProgressStatusLogo src={recycle} state={true} />
            <ProgressFontType1>
              {intl.formatMessage({ id: "quiz.progressbar.recycling" })}
            </ProgressFontType1>
          </ProgressIcon>
          <ProgressIcon state={50}>
            <ProgressStatusLogo src={bottle} state={true} />
            <ProgressFontType1>
              {intl.formatMessage({ id: "quiz.progressbar.bring" })}
            </ProgressFontType1>
          </ProgressIcon>
          <ProgressIcon state={70}>
            <ProgressStatusLogo src={finish} state={true} />
            <ProgressFontType1>
              {intl.formatMessage({ id: "quiz.progressbar.finish" })}
            </ProgressFontType1>
          </ProgressIcon>
        </ProgressDiv>
        <SubDiv></SubDiv>
      </MainDiv2>
    )
  ) : (
    <div>
      <ProgressDiv2>
        {props.cond === false ? (
          progress3 <= 100 && progress3 != 0 ? (
            <ProgressStatusLogo src={bottle} state={true} />
          ) : progress2 <= 100 && progress2 != 0 ? (
            <ProgressStatusLogo src={recycle} state={true} />
          ) : (
            progress1 <= 100 && <ProgressStatusLogo src={home} state={true} />
          )
        ) : (
          <ProgressStatusLogo src={finish} state={true} />
        )}
      </ProgressDiv2>
      <SubDiv></SubDiv>
    </div>
  )
}

const Quiz = props => {
  const intl = useIntl()
  const [sectionArrCount, setSectionArrCount] = useState(0)
  const [quizArrCount, setQuizArrCount] = useState(0)

  const [buttonSelected, setButtonSelected] = useState(0)
  const [selectorValue, setSelectorValue] = useState("")

  const quizArrCountIncrement = () => {
    if (
      (typeof buttonSelected === "string" && buttonSelected.length > 0) ||
      typeof buttonSelected === "number" ||
      (typeof selectorValue === "string" && selectorValue.length)
    ) {
      if (
        typeof buttonSelected === "number" &&
        !(typeof selectorValue === "string" && selectorValue.length)
      ) {
        if (sectionArrCount === 2) {
          props.setUserData(prev => [
            ...prev,
            {
              type: quizData[sectionArrCount][quizArrCount].type,
              name: buttonSelected,
              value:
                quizData[sectionArrCount][quizArrCount].value * buttonSelected,
            },
          ])
        } else {
          return alert("Please Select Your Answer!")
        }
      } else if (
        typeof buttonSelected === "string" &&
        buttonSelected.length > 0
      ) {
        props.setUserData(prev => [
          ...prev,
          {
            type: quizData[sectionArrCount][quizArrCount].type,
            name: buttonSelected,
            value:
              quizData[sectionArrCount].find(
                x => x.type === quizData[sectionArrCount][quizArrCount].type
              ).value *
              selection.select.find(x => x.type === buttonSelected).value,
          },
        ])
      } else {
        props.setUserData(prev => [
          ...prev,
          {
            type: quizData[sectionArrCount][quizArrCount].type,
            name: selectorValue,
            value: quizData[sectionArrCount]
              .find(
                x => x.type === quizData[sectionArrCount][quizArrCount].type
              )
              .info.find(x => x.name === selectorValue)?.value,
          },
        ])
      }

      if (quizData[sectionArrCount].length - 1 > quizArrCount) {
        setQuizArrCount(prev => prev + 1)
      } else if (quizData.length - 1 > sectionArrCount) {
        setSectionArrCount(prev => prev + 1)
        setQuizArrCount(0)
      } else {
        props.nextSection()
      }
      setSelectorValue("")
      setButtonSelected(0)
    } else {
      return alert("Please Select Your Answer!")
    }
  }

  const quizArrCountDecrement = () => {
    if (!(sectionArrCount === 0 && quizArrCount === 0)) {
      if (quizArrCount > 0) {
        setQuizArrCount(prev => prev - 1)
      } else {
        setSectionArrCount(prev => {
          setQuizArrCount(quizData[prev - 1].length - 1)
          return prev - 1
        })
      }
    }
    setSelectorValue("")
    setButtonSelected(0)
  }

  useEffect(() => {
    props.setUserData(
      props.userData.filter(
        data => data.type !== quizData[sectionArrCount][quizArrCount].type
      )
    )
  }, [quizArrCount])

  const handleChange = e => {
    setSelectorValue(e.target.value)
  }

  const numHandler = cond => {
    if (cond === "sum" && buttonSelected < 7) {
      setButtonSelected(prev => prev + 1)
    } else if (cond === "deduct" && buttonSelected > 0) {
      setButtonSelected(prev => prev - 1)
    }
  }

  return (
    <MainDiv>
      <ProgressBar
        sectionArrCount={sectionArrCount}
        quizArrCount={quizArrCount}
        quizData={quizData}
        cond={false}
      />
      <QuizSection>
        <QuestionContainer>
          <QuestionContainerFontType1>
            {quizData[sectionArrCount][quizArrCount].question[intl.locale]}
          </QuestionContainerFontType1>
          {quizData[sectionArrCount][quizArrCount].value ? (
            sectionArrCount === 1 ? (
              <SelectionContainer>
                {selection.select.map(x => (
                  <ButtonGroupButton
                    buttonColor={x.type === buttonSelected}
                    onClick={() => setButtonSelected(x.type)}
                  >
                    {x.language[intl.locale]}
                  </ButtonGroupButton>
                ))}
                {/* <StarterContainerFontType3>{buttonSelected}</StarterContainerFontType3> */}
              </SelectionContainer>
            ) : (
              <div>
                <NumButtonContainer>
                  <NumButton onClick={() => numHandler("deduct")}>-</NumButton>
                  <NumButtonValueDisplayField>
                    {buttonSelected}
                  </NumButtonValueDisplayField>
                  <NumButton onClick={() => numHandler("sum")}>+</NumButton>
                </NumButtonContainer>
                <StarterContainerFontType3>
                  {intl.formatMessage({ id: "quiz.button.week" })}
                </StarterContainerFontType3>
              </div>
            )
          ) : (
            <div>
              <Selector value={selectorValue} onChange={handleChange}>
                <option selected value="">
                  {" "}
                  -- select an option --{" "}
                </option>
                {quizData[sectionArrCount][quizArrCount].info.map(x => (
                  <option value={x.name}>{x.language[intl.locale]}</option>
                ))}
              </Selector>
              {/* <div>{selectorValue}</div> */}
            </div>
          )}
          <ButtonContainer>
            <StarterContainerButton onClick={() => quizArrCountIncrement()}>
              {intl.formatMessage({ id: "quiz.button.next" })}
            </StarterContainerButton>
            {!(sectionArrCount === 0 && quizArrCount === 0) && (
              <StarterContainerButton2 onClick={() => quizArrCountDecrement()}>
                {intl.formatMessage({ id: "quiz.button.back" })}
              </StarterContainerButton2>
            )}
          </ButtonContainer>
        </QuestionContainer>
      </QuizSection>
    </MainDiv>
  )
}

const QuizPage = props => {
  const [section, setSection] = useState(0)
  const [userData, setUserData] = useState([])
  const pageUrl = props.location.href
  const { width } = useSceenSize()
  const intl = useIntl()
  console.log(intl.locale)

  const nextSection = () => {
    setSection(prev => prev + 1)
  }

  let condBYO = false
  let res = 0
  let state = 0
  let reduced = 0
  let footprint = 0
  let tree = 0
  userData.forEach(x => {
    if (Number.isInteger(x.name)) {
      res += x.value
    } else {
      if (x.type === "state") {
        state = x.value
      } else if (x.value !== 0) {
        condBYO = true
        res += x.value * (1 + 0.3152)
      }
    }
  })
  if (condBYO) {
    res += state * (1 + 0.3152)
  } else {
    res += state
  }

  useEffect(() => {
    if (section === 3) {
      reduced = (state - res) * 1000
      footprint = res * 1000
      tree = Math.ceil(Math.abs(state - res) / 12.3)
      storeData(userData, reduced, footprint, tree)
    }
  }, [section])

  return (
    <MainContainer fluid>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trash Encyclopedia</title>
      </Helmet>
      <Header2 state="mainpage" />
      <QuizContainer section={section}>
        {section !== 3 && (
          <QuizLogoContainer>
            <QuizLogo src={wasteQuizLogo} />
          </QuizLogoContainer>
        )}
        {section === 0 ? (
          <StarterContainerSection nextSection={() => nextSection()} />
        ) : section === 1 ? (
          // <div>Quiz</div>
          <Quiz
            nextSection={nextSection}
            userData={userData}
            setUserData={setUserData}
          />
        ) : section === 2 ? (
          <MainDiv>
            <ProgressBar cond={true} />
            <QuizSection>
              <QuestionContainer>
                <ThankYouFont>
                  {intl.formatMessage({ id: "quiz.result.thank" })}
                </ThankYouFont>
                <StarterContainerButton onClick={() => nextSection()}>
                  {intl.formatMessage({ id: "quiz.result.show" })}
                </StarterContainerButton>
              </QuestionContainer>
            </QuizSection>
          </MainDiv>
        ) : (
          <ResultContainer>
            <QuizLogoContainer>
              <QuizLogo src={wasteQuizLogo} />
            </QuizLogoContainer>
            <ResultHead>
              <ResultTitle>
                {intl.formatMessage({ id: "quiz.result.result" })}
              </ResultTitle>
              <LogoDiv>
                <InfoLogo src={info} />
                <ResultTitle2>
                  <a
                    href="https://docs.google.com/document/d/1usmTxYrtOZONCh8CQx0TKWvtMBkHf8CU_oDHtvxppzs/edit?usp=sharing"
                    target="_blank"
                  >
                    {intl.formatMessage({ id: "quiz.result.read" })}
                  </a>
                </ResultTitle2>
              </LogoDiv>
            </ResultHead>
            <ResultTitleLine />
            {res * 1000 >= 0 ? (
              <ResultSection1>
                {width > 992 ? (
                  <div>
                    <div>{intl.formatMessage({ id: "quiz.result.p1" })}</div>
                    <div>{intl.formatMessage({ id: "quiz.result.p2" })}</div>
                  </div>
                ) : (
                  <MobilePaddingDiv>
                    <div>
                      {intl.formatMessage({ id: "quiz.result.p1" })}{" "}
                      {intl.formatMessage({ id: "quiz.result.p2" })}
                    </div>
                  </MobilePaddingDiv>
                )}
                <ResultSectionText2>
                  {Math.abs((res * 1000).toFixed(2)).toLocaleString()}
                </ResultSectionText2>
                <div>{intl.formatMessage({ id: "quiz.result.p3" })}</div>
              </ResultSection1>
            ) : (
              <ResultSection1>
                {width > 992 ? (
                  <div>
                    <div>{intl.formatMessage({ id: "quiz.result.p1" })}</div>
                    <div>{intl.formatMessage({ id: "quiz.result.p4" })}</div>
                  </div>
                ) : (
                  <MobilePaddingDiv>
                    <div>
                      {intl.formatMessage({ id: "quiz.result.p1" })}{" "}
                      {intl.formatMessage({ id: "quiz.result.p4" })}
                    </div>
                  </MobilePaddingDiv>
                )}
                <ResultSectionText2>
                  {Math.abs((res * 1000).toFixed(2)).toLocaleString()}
                </ResultSectionText2>
                <div>{intl.formatMessage({ id: "quiz.result.p3" })}</div>
              </ResultSection1>
            )}
            <ResultSection2>
              {intl.formatMessage({ id: "quiz.result.p5" })}
            </ResultSection2>
            <ResultSection3>
              <div>
                <div>{intl.formatMessage({ id: "quiz.result.p6" })}</div>
                <ResultSectionText1>
                  {Math.abs(((state - res) * 1000).toFixed(2)).toLocaleString()}
                </ResultSectionText1>
                <div>{intl.formatMessage({ id: "quiz.result.p7" })}</div>
              </div>
            </ResultSection3>
            <ResultSection3>
              <div>{intl.formatMessage({ id: "quiz.result.p8" })}</div>
              <ResultSectionText1>
                {Math.ceil(Math.abs(state - res) / 12.3)}
              </ResultSectionText1>
              <div>{intl.formatMessage({ id: "quiz.result.p9" })}</div>
            </ResultSection3>
            <ResultSection4>
              <ResultSectionText3>
                {intl.formatMessage({ id: "quiz.result.p10" })}
              </ResultSectionText3>
              <ResultSectionText4>
                {intl.formatMessage({ id: "quiz.result.p11" })}
              </ResultSectionText4>
              <ResultSectionText5>
                {intl.formatMessage({ id: "quiz.result.p12" })}
              </ResultSectionText5>
              <ResultButton
                onClick={() => (window.location.href = window.location.href)}
              >
                <AgainIcon />
                <AgainText>
                  {intl.formatMessage({ id: "quiz.result.again" })}
                </AgainText>
              </ResultButton>
              <IconGroup>
                <IconDiv>
                  <FacebookShareButton
                    url={pageUrl}
                    quote="Zero Waste Malaysia"
                  >
                    <FacebookShareIcon size={32} round />
                  </FacebookShareButton>
                </IconDiv>
                <IconDiv>
                  <WhatsappShareButton
                    url={pageUrl}
                    title="Zero Waste Malaysia"
                    separator=":: "
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </IconDiv>
                <IconDiv>
                  <LinkedinShareButton
                    url={pageUrl}
                    quote="Zero Waste Malaysia"
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </IconDiv>
                <IconDiv>
                  <TelegramShareButton
                    url={pageUrl}
                    quote="Zero Waste Malaysia"
                  >
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
                </IconDiv>
                <IconDiv>
                  <TwitterShareButton url={pageUrl} quote="Zero Waste Malaysia">
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                </IconDiv>
              </IconGroup>
            </ResultSection4>
          </ResultContainer>
        )}
      </QuizContainer>
      <div className="section fp-auto-height">
        <Footer state="mainpagefooter" />
      </div>
    </MainContainer>
  )
}

export default QuizPage
