import { initializeApp } from "firebase/app"
import { getFirestore, collection, addDoc } from "firebase/firestore"
import { firebaseConfig, collectionName } from "./config"

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

const storeData = (answer, reduced, footprint, tree) => {
  try {
    const docRef = addDoc(collection(db, collectionName), {
      answer: answer,
      reduced: reduced,
      footprint: footprint,
      tree: tree,
      datetime: Date().toLocaleString(),
    })
    console.log("Document written with ID: ", docRef.id)
  } catch (e) {
    console.error("Error adding document: ", e)
  }
}

export { storeData }
